<template>
    <div>
        <div v-if="!pageIndexApiDataIsLoading">
            <company-licence-component/>

            <div class="row">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5" to="/monitoring"><i class="fa fa-tv fa-fw fa-lg"></i>
                    </router-link>
                    <router-link class="btn btn-white m-r-5" to="/monitoring/prospection"><i
                            class="fa fa-map fa-fw fa-lg"></i> Prospection
                    </router-link>
                    <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales"><i
                            class="fa fa-chart-bar fa-chart-bar fa-lg text-orange"></i> Vente &amp; Facturation
                    </router-link>
                    <router-link class="btn btn-white m-r-5" to="/monitoring/vaults"><i
                            class="fa fa-dollar-sign fa-fw fa-lg"></i> Caisse &amp; Dépenses
                    </router-link>
                    <router-link class="btn btn-white m-r-5" to="/monitoring/scm"><i
                            class="fa fa-shopping-basket fa-fw fa-lg"></i> Appro. &amp; Achats
                    </router-link>
                    <router-link class="btn btn-white m-r-5" to="/monitoring/production"><i
                            class="fa fa fa-flask fa-fw fa-lg"></i> Usine &amp; Production
                    </router-link>
                    <router-link class="btn btn-white m-r-5" to="/monitoring/stock"><i
                            class="fa fa-cube fa-fw fa-lg"></i> Stock &amp; Inventaires
                    </router-link>
                    <router-link class="btn btn-white m-r-5" to="/monitoring/admin"><i class="fa fa-cogs fa-fw fa-lg"></i> Console Admin</router-link>
                </div>
            </div>

            <!--<h1 class="page-header">Comptes clients  <small class="lead">.</small></h1>-->
            <hr class="bg-grey-lighter"/>

          <div class="row m-b-5">
            <div class="col-md-12 m-b-0">
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales"><i class="fa fa-chart-bar fa-fw fa-lg"></i></router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/sales-periodic-report-per-warehouse"><i class="fa fa-calendar fa-fw fa-lg"></i> Suivi Vente &amp; Encaissement</router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/clients-account-summary"><i class="fa fa-users fa-fw fa-lg"></i> Relevé Comptes Clients</router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/invoice-ageing"><i class="fa fa-clock fa-fw fa-lg"></i> Echéances Factures</router-link>
              <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales/sleeping-clients-on-list"><i class="fa fa-bed fa-fw fa-lg text-orange"></i> Clients Dormants</router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/sales-orders"><i class="fa fa-file-alt fa-fw fa-lg"></i> Factures Spéciales</router-link>
              <router-link class="btn btn-white m-r-5" to="/monitoring/sales/order-payments"><i class="fa fa-dollar-sign fa-fw fa-lg"></i> Règlements Spéciaux</router-link>
            </div>
          </div>

            <div class="row m-b-5">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales/sleeping-clients-on-list"><i class="fa fa-list fa-fw fa-lg text-orange"></i> Voir la liste</router-link>
                    <router-link class="btn btn-white m-r-5" to="/monitoring/sales/sleeping-clients-on-map"><i class="fa fa-map fa-fw fa-lg"></i> Voir sur une carte</router-link>
                </div>
            </div>

            <div class="row m-b-5">
                <div class="col-md-12 m-b-0">
                    <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/sales/sleeping-clients-on-list"><i class="fa fa-map-marker-alt fa-fw fa-lg text-orange"></i> Par Localité</router-link>
                </div>
            </div>

            <div v-if="pageIndexApiData != null">
              <div class="row text-right m-b-5">
                <div class="col-12">
                  <a href="#" class="btn btn-dark m-l-5" @click="$bvModal.show('formModal')"><i class="fa fa-filter fa-fw fa-lg"></i></a>
                </div>
              </div>

              <div class="row">
                <div class="col-12" v-if="queryApiData != null">
                    <div class="invoice">
                        <!-- begin invoice-company -->
                        <div class="invoice-company">
                                        <span class="pull-right hidden-print">
                                            <a :href="queryApiData.document_url_pdf"
                                               target="_blank" class="btn btn-sm btn-white m-b-10 mr-2"
                                               v-if="queryApiData.document_url_pdf != null"><i
                                                    class="fa fa-file-pdf text-danger fa-fw fa-lg"></i> PDF</a>
                                            <a :href="queryApiData.document_url_excel"
                                               target="_blank" class="btn btn-sm btn-white m-b-10 mr-2"
                                               v-if="queryApiData.document_url_excel != null"><i
                                                    class="fa fa-file-excel text-green fa-fw fa-lg"></i> Excel</a>
                                        </span>
                            <h1>{{queryApiData.client_group_name}} -{{queryApiData.location_name}} <br/>
                                <small> Comptes dormants depuis au moins {{queryApiData.day_count_min}} jours.</small>
                            </h1>
                        </div>
                        <!-- end invoice-company -->
                        <!-- begin invoice-header -->
                        <div class="invoice-header">
                            <div class="invoice-from">
                                <small>De chez</small>
                                <address class="m-t-5 m-b-5">
                                    <strong class="text-inverse">{{queryApiData.company_name}}</strong><br/>
                                    {{queryApiData.company_address_line}}<br/>
                                    {{queryApiData.company_cluster_name}},
                                    {{queryApiData.company_location_name}}<br/>
                                    Téléphone: {{queryApiData.company_phone}}<br/>
                                </address>
                            </div>
                            <div class="invoice-to">

                            </div>
                            <div class="invoice-date">
                                <small>Localité</small>
                                <div class="date text-inverse m-t-5">
                                    {{queryApiData.client_group_name}} - {{queryApiData.location_name}}
                                </div>
                                <small>Durée minimale de sommeil</small>
                                <div class="date text-inverse m-t-5">
                                    {{queryApiData.day_count_min}} Jours
                                </div>
                            </div>
                        </div>

                        <!-- end invoice-header -->
                        <!-- begin invoice-content -->
                        <div class="invoice-content">
                            <div>
                                <vue-good-table
                                        :columns="columns"
                                        :rows="queryApiData.clients"
                                        @on-row-click="onRowClick"
                                        :lineNumbers="true"
                                        :search-options="{ enabled: true, placeholder: 'Recherche rapide'}"
                                        :pagination-options="{
                                        enabled: true,
                                        position: 'bottom',
                                        nextLabel: 'Suiv.',
                                        prevLabel: 'Préc.',
                                        rowsPerPageLabel: 'Entrées par page',
                                        ofLabel: 'de',
                                        pageLabel: 'page',
                                        allLabel: 'Tous',

                                     }"
                                        :selectOptions="{
                                            enabled: false,
                                            selectOnCheckboxOnly: true,
                                            selectionInfoClass: 'alert alert-green m-b-0',
                                            selectionText: 'Entrées sélectionnées',
                                            clearSelectionText: 'Annuler la sélection',
                                        }"
                                >
                                    <div slot="selected-row-actions" style="margin: -2px 0;">
                                        <button class="btn btn-xs btn-primary m-r-5">Action 1</button>
                                        <button class="btn btn-xs btn-grey">Action 2</button>
                                    </div>
                                    <!--
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'image'">
                                            <img v-bind:src="props.row.image" class="rounded" width="24" style="margin: -3px" />
                                        </span>
                                                                    <span v-else>
                                            {{props.formattedRow[props.column.field]}}
                                        </span>
                                    </template>
                                    -->
                                </vue-good-table>
                            </div>
                        </div>
                        <!-- end invoice-content -->
                        <!-- begin invoice-note --
                        <div class="invoice-note">
                            * Make all cheques payable to [Your Company Name]<br/>
                            * Payment is due within 30 days<br/>
                            * If you have any questions concerning this invoice, contact [Name, Phone
                            Number, Email]
                        </div>
                         end invoice-note -->
                        <!-- begin invoice-footer -->
                        <div class="invoice-footer">
                            <p class="text-center m-b-5 f-w-600">
                                MERCI DE VOTRE FIDELITE
                            </p>
                            <p class="text-center">
                                <span class="m-r-10"><i class="fa fa-fw fa-lg fa-globe"></i> {{queryApiData.company_website}}</span>
                                <span class="m-r-10"><i class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{queryApiData.company_phone}}</span>
                                <span class="m-r-10"><i class="fa fa-fw fa-lg fa-envelope"></i> {{queryApiData.company_email}}</span>
                            </p>
                        </div>
                        <!-- end invoice-footer -->
                    </div>
                </div>
                <div class="col-12" v-else>
                <div class="panel">
                  <div class="panel-body">
                    <div class="invoice">
                      <div class="invoice-company">

                        <h3 class="page-header">Suivi des comptes clients dormants.<br/>
                          <small class="lead">Dans cette section, vous pouvez suivre les comptes clients n'ayant pas
                            éffectuer d'achat depuis un certain temps afin de les relancer.
                          </small></h3>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>

              <!-- Dialogs -->
              <b-modal
                  centered
                  hide-header
                  hide-footer
                  no-close-on-esc
                  no-close-on-backdrop
                  hide-header-close
                  id="formModal"
                  title="Modal Title"
                  size="md"
                  @show="()=>{
              this.queryApiDataErrorMessage = null;
            }"
                  @hidden="()=>{}">

                <div class="row ">
                  <p class="col-12 text-right">
                    <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('formModal')"><i
                        class="fa fa-times fa-fw fa-lg"></i></a>
                  </p>
                </div>

                <h1 class="page-header">Choisir un type de client <small class="lead">.</small></h1>
                <div class="row" v-if="queryApiDataIsLoading">
                  <div class="col-12 m-b-10">
                    <small class="lead text-warning"><i class="fas fa-spinner fa-pulse"></i> Chargement en cours...</small>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-12" v-if="queryApiDataErrorMessage != null">
                    <div class="alert alert-danger">
                      {{ queryApiDataErrorMessage }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <form
                        @submit.prevent="queryData">
                      <div class="form-group row">
                        <div class="col-12">
                          <label>Choisir un type de client</label>
                          <v-select label="name" :options="pageIndexApiData.client_groups"
                                    :reduce="item => item.id"
                                    v-model="form.client_group_id"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-12">
                          <label>Choisir un code localité</label>
                          <v-select label="name" :options="pageIndexApiData.location_codes"
                                    :reduce="item => item.code"
                                    v-model="form.location_code"/>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-6">
                          <label>Sommeil Minimal (en jours)</label>
                          <input v-model="form.day_count_min" type="number" name="day_count_min"
                                 class="form-control"
                                 placeholder="Sommeil Minimal (en jours)">
                        </div>
                        <div class="col-6">
                          <label>Sommeil Maximal (en jours)</label>
                          <input v-model="form.day_count_max" type="number" name="day_count_max"
                                 class="form-control"
                                 placeholder="Sommeil Maximal">
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-12">
                          <label>Choisir une date de référence</label>
                          <date-picker
                              v-model="form.date"
                              :config="{
                                  format:'DD-MM-YYYY',
                                  useCurrent:false
                                }">
                          </date-picker>
                        </div>
                      </div>

                      <div class="form-group row">
                        <!--
                        <div class="col-6">
                          <div class="checkbox checkbox-css checkbox-inline">
                            <input type="checkbox" id="pdf" v-model="form.to_pdf"/>
                            <label for="pdf"><i class="fas fa-download text-warning"></i>
                              Télécharger au format PDF</label>
                          </div>
                        </div>
                        -->
                        <div class="col-6">
                          <div class="checkbox checkbox-css checkbox-inline">
                            <input type="checkbox" id="excel" v-model="form.to_excel"/>
                            <label for="excel"><i class="fas fa-download text-warning"></i>
                              Télécharger au format Excel</label>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <div class="col-12 text-right">
                          <button :disabled="queryApiDataIsLoading" type="submit" class="btn btn-dark">
                            <i class="fas fa-spinner fa-spin" v-if="queryApiDataIsLoading"></i>
                            <i class="fas fa-filter" v-else></i> Filtrer
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <!--
                <div class="row ">
                  <p class="col-12 text-right">
                    <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
                    <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
                  </p>
                </div>
                -->
              </b-modal>
              <b-modal
                  centered
                  hide-header
                  hide-footer
                  no-close-on-esc
                  no-close-on-backdrop
                  hide-header-close
                  id="clientModal"
                  title="Modal Title"
                  size="xl"
                  @show="()=>{}"
                  @hidden="()=>{}">

                <div class="row ">
                  <p class="col-12 text-right">
                    <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('clientModal')"><i
                        class="fa fa-times fa-fw fa-lg"></i></a>
                  </p>
                </div>

                <client-account-component :itemId="selectedClientId"/>

                <!--
                <div class="row ">
                  <p class="col-12 text-right">
                    <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
                    <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
                  </p>
                </div>
                -->
              </b-modal>
            </div>
        </div>
        <div class="panel-loader" v-else><span class="spinner-small"></span></div>
    </div>
</template>


<script>
    import pageApi from '../../store/api/monitoring/location-sleeping-clients-on-list-dashboard-page-api'
    import LocalStorage from "../../store/local-storage";
    import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'
    import ClientAccountComponent from "@/components/app/ClientAccountComponent";

    export default {
        name: 'location-sleeping-clients-on-list-dashboard-page-component',
        components: {
          ClientAccountComponent,
            CompanyLicenceComponent,
        },
        props: {},
        watch: {},
        computed: {},
        methods: {
            //Initialization Methods
            loadPageScripts() {
            },

          formatDateInput (value) {
            if (value) {
              return window.moment(String(value)).format(window.appDefaultDisplayDateTimeFormat)
            }
            return null;
          },

            //
            onRowClick(params) {
                // params.row - row object
                // params.pageIndex - index of this row on the current page.
                // params.selected - if selection is enabled this argument indicates selected or not
                // params.event - click event
              this.selectedClientId = params.row.id;
              this.$bvModal.show('clientModal');
            },


            //Other Methods
            async getPageIndexApiData() {
                this.pageIndexApiDataIsLoading = true;
                this.pageIndexApiDataErrorMessage = null;
                try {
                    const response = await pageApi.getIndexData();
                    //console.log(response);
                    this.pageIndexApiData = response.data;
                    this.pageIndexApiDataIsLoading = false;
                    this.pageIndexApiDataErrorMessage = null;
                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            LocalStorage.clearSessionData();
                            this.$router.push('login');
                        } else {
                            this.pageIndexApiDataIsLoading = false;
                            this.pageIndexApiDataErrorMessage = error.response.data.message;
                            //
                            this.$swal({
                                icon: 'error',//'info','success','warning','error','question',
                                toast: true,
                                position: 'bottom-end',
                                timer: 3000,
                                showConfirmButton: false,
                                title: 'HTTP ' + error.response.status,
                                text: error.response.data.message,
                            })
                        }
                    } else {
                        this.pageIndexApiDataIsLoading = false;
                        this.pageIndexApiDataErrorMessage = error.message;
                        //
                        this.$swal({
                            icon: 'error',//'info','success','warning','error','question',
                            toast: true,
                            position: 'bottom-end',
                            timer: 3000,
                            showConfirmButton: false,
                            //title: error.message
                            text: error.message,
                        })
                    }
                }
            },

            async queryData() {
                this.queryApiDataIsLoading = true;
                this.queryApiDataErrorMessage = null;
                this.queryApiDataValidationErrors = [];
                try {
                    const response = await pageApi.queryData({
                        location_code: this.form.location_code,
                        client_group_id: this.form.client_group_id,
                        day_count_min: this.form.day_count_min,
                        day_count_max: this.form.day_count_max,
                        date: this.form.date,
                        to_pdf: this.form.to_pdf,
                        to_excel: this.form.to_excel
                    });
                    //console.log(response);
                    this.queryApiData = response.data;
                    this.queryApiDataIsLoading = false;
                    this.queryApiDataErrorMessage = null;
                    this.queryApiDataValidationErrors = [];

                  this.$bvModal.hide('formModal');

                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        this.queryApiDataIsLoading = false;
                        this.queryApiDataErrorMessage = error.response.data.message;
                        if (error.response.status === 422) {
                            this.queryApiDataValidationErrors = error.response.data.errors;
                        } else {
                            this.queryApiDataValidationErrors = [];
                        }
                    } else {
                        this.queryApiDataIsLoading = false;
                        this.queryApiDataErrorMessage = error.message;
                        this.queryApiDataValidationErrors = [];
                    }
                }
            },
        },
        data: function () {


            return {
                appName: window.appConfig.appName,
                appCurrency: window.appConfig.appCurrency,
                intervalId01: 0,

              selectedClientId: null,

                form: {
                    location_code: null,
                    client_group_id: null,
                    day_count_min: 7,
                    day_count_max: 30,
                    date: null,
                    to_pdf: false,
                    to_excel: false,
                },

                columns: [
                    {
                        label: 'Code Entité',
                        field: 'entity_code',
                        tdClass: 'text-nowrap',
                        thClass: 'text-nowrap'
                    },
                    {
                        label: 'Code Localité',
                        field: 'location_code',
                        tdClass: 'text-nowrap',
                        thClass: 'text-nowrap'
                    },
                    {
                        label: 'Code Client',
                        field: 'erp_code',
                        thClass: 'text-nowrap',
                        tdClass: 'text-nowrap'
                    },
                    {
                        label: 'Désignation',
                        field: 'name',
                        thClass: 'text-nowrap',
                        tdClass: 'text-nowrap'
                    },
                    {
                        label: 'Téléphone',
                        field: 'phone',
                        thClass: 'text-nowrap',
                        tdClass: 'text-nowrap'
                    },
                    {
                        label: 'Type Client',
                        field: 'group_name',
                        thClass: 'text-nowrap',
                        tdClass: 'text-nowrap'
                    },
                    {
                        label: 'Localité',
                        field: 'location_name',
                        tdClass: 'text-nowrap',
                        thClass: 'text-nowrap'
                    },
                    {
                        label: 'Sect. Activité',
                        field: 'type_name',
                        thClass: 'text-nowrap',
                        tdClass: 'text-nowrap'
                    },
                    {
                        label: 'Zone',
                        field: 'commercial_cluster_name',
                        thClass: 'text-nowrap',
                        tdClass: 'text-nowrap'
                    },
                    {
                        label: 'Secteur',
                        field: 'commercial_sector_name',
                        thClass: 'text-nowrap',
                        tdClass: 'text-nowrap'
                    },
                    {
                        label: 'Segmentation',
                        field: 'commercial_zone_name',
                        thClass: 'text-nowrap',
                        tdClass: 'text-nowrap'
                    },
                    {
                        label: 'Addresse',
                        field: 'address_line',
                        thClass: 'text-nowrap',
                        tdClass: 'text-nowrap'
                    },
                ],

                //API Data
                //
                pageIndexApiData: null,
                pageIndexApiDataIsLoading: false,
                pageIndexApiDataErrorMessage: null,

                queryApiData: null,
                queryApiDataIsLoading: false,
                queryApiDataErrorMessage: null,
                queryApiDataValidationErrors: [],
            };
        },
        created: function () {
            this.getPageIndexApiData();
        },
        mounted: function () {
            this.loadPageScripts();
        },
        beforeDestroy: function () {
        },
    }
</script>
